<template>
  <div :id="id" :style="{width: width,height: height}"></div>
</template>

<script>
  import * as echarts from "echarts";
  import moment from "moment";

  export default {
    props:{
      id:{
        type:String,
        default:"axisBar",
      },
      formatData:{
        type:Object,
        default:function() {
          return{
            xAxis: [],
            data:[],
            record:[],
          };
        },
      },

      width:{
        type:String,
        default:"1000px",
      },
      height:{
        type:String,
        default: "340px",
      },
      name:{
        type:String,
        default:"",
      },
      option:Object,
    },
    watch:{
      formatData:{
        handler() {
          this.drawPic();
        },
        deep:true,
      },
    },
    mounted() {

      this.drawPic();
    },
    methods:{
      drawPic() {
        const time = (()=> { // 立即执行函数
          let now = new Date(this.formatData.xaxis[0]);  // 获得当前的时间
          let res = []; // 存放时间的数组
          let len = 5; // 要存几个时间？
          while (len--) {
            res.unshift(now.toLocaleTimeString().replace(/^\D*/, "")); // 转换时间，大家可以打印出来看一下
            now = new Date(+now - 2000); // 延迟几秒存储一次？
          }
          return res;
        })();
        console.log(time,52);
        const arr = this.formatData.record.map(item=>{
          let obj ={};
          obj.value= [moment(item.timestamp).format("YYYY-MM-DD hh:mm:ss"),item.online?1:0];
          obj.name = moment(item.timestamp).format("YYYY-MM-DD hh:mm:ss");
          return obj;
        });
        console.log(arr,68);
        const chart = echarts.init(document.getElementById(this.id));
        const option = {
          tooltip: {
            trigger: "axis",
            formatter: function(params) {
              params = params[0];
              var date = new Date(params.name);
              return (
                moment(date).format("YYYY-MM-DD hh:mm:ss")+
                (params.value[1]?"在线":"离线")
              );
            },
            axisPointer: {
              animation: false,
            },
          },
          xAxis: {
            type: "time",
            splitLine: {
              show: false,
            },

          },
          yAxis: {
            type: "value",
            // show:false,

          },
          series: [
            {
              data: arr,
              type: "line",
            },
          ],
        };
        chart.setOption(option);
      },
    },
  };
</script>

<style scoped>

</style>