<template>
  <el-dialog
    v-el-drag-dialog
    append-to-body
    :visible.sync="dialogVisible"
    title="在线记录"
    class="small-padding"
    width="600px"
    :modal="false"
    top="3vh">
    <vm-table
      ref="vmTable"
      :filter.sync="filter"
      url="deviceStatusLogs">
      <el-table-column prop="deviceNo" :label="$l('device.no','终端编号')" align="center"></el-table-column>
      <el-table-column prop="statusDesc" label="在线状态" align="center"></el-table-column>
      <el-table-column prop="createTime" :label="$l('common.createTime','时间')" align="center"></el-table-column>
    </vm-table>
  </el-dialog>
</template>
<script>
  export default {
    components: {},
    data() {
      return {
        dialogVisible: false,
        filter: {
          deviceNo: "",
        },
      };
    },
    methods: {
      open(deviceNo) {
        this.dialogVisible = true;
        this.filter.deviceNo = deviceNo;
        this.$nextTick(() => {
          this.$refs.vmTable.getList(1);
        });
      },
    },
  };
</script>
<style lang="scss" scoped>
</style>
